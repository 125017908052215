<template>
    <div class="page">
        <van-nav-bar :title="userInfo && !userInfo.mobile ?'绑定个人信息':'编辑个人信息'" left-text="返回" left-arrow @click-left="back"></van-nav-bar>

        <van-form class="edit_form"  ref="editForm">
            <van-field class="form_input" required v-model="userInfo.username" name="username" type="text" label="真实姓名" placeholder="请输入" :rules="rules.username"/>
            <van-field class="form_input" required v-model="userInfo.mobile" name="mobile" type="text" label="手机号码" placeholder="请输入"  :rules="rules.mobile" >
                <template #button>
                    <van-button size="small" type="primary" :disabled="seconds> 0" @click="sendVcode()">发送验证码{{seconds > 0?'('+seconds+')':''}}</van-button>
                </template>
            </van-field>
            <van-field class="form_input" required v-model="userInfo.vcode" name="vcode" type="text" label="验证码" placeholder="请输入"   :rules="rules.vcode"/>
            <div class="form_btn" style="margin: 16px;">
                <van-button round block type="info" native-type="submit" @click="fillUserInfo"> 提交 </van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
import { reLoadLoginInfo } from '../../../utils';
    export default {
        data() {
            return {
                userInfo: {},
                seconds:0,
                timerId:null,
                rules: {
                    username: [{ required: true , message:'', trigger:'change'}],
                    mobile: [{ required: true , message:'', trigger:'change'}],
                    vcode: [{ required: true, message:'', trigger:'change' }],
                }
            };
        },
        mounted(){
            this.userInfo = {...this.$user};
        },
        methods: {
            sendVcode() {
                if (this.userInfo.mobile == null || this.userInfo.mobile == '') {
                    this.$toast.fail('手机号码不能为空');
                    return;
                }
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                this.$axios.get("/vcode/send",{params:{mobile:this.userInfo.mobile}})
                .then(res => {
                    if(res.code == "ok") {
                        toast.clear();
                        this.$toast.success('短信验证码发送成功');
                        this.seconds = 60;//开始倒计时
                        this.startTimer();
                    } else {
                        toast.clear();
                        this.$toast.fail(res.msg);
                    }
                }).catch(err=> {toast.clear();});
            },
            startTimer() {
                const _this = this;
                this.timerId = setInterval(function() {
                    _this.seconds -= 1;
                    if (_this.seconds == 0) {
                        clearInterval(_this.timerId);
                    }
                }, 1000); 
            },
            back() {
                this.$back();
            },

            fillUserInfo() {
                const _this = this;
                if (this.userInfo.username == null || this.userInfo.username == '') {
                    // _this.$toast.fail('真实姓名不能为空');
                    return;
                }
                if (this.userInfo.mobile == null || this.userInfo.mobile == '') {
                    // _this.$toast.fail('手机号码不能为空');
                    return;
                }
                if (this.userInfo.vcode == null || this.userInfo.vcode == '') {
                    // _this.$toast.fail('验证码不能为空');
                    return;
                }
                const toast = _this.$toast.loading({
                            forbidClick: true,
                            duration: 0
                        });
                        _this.$axios.post("/wechat/fillUserInfo", _this.userInfo)
                        .then(res => {
                            if(res.code == "ok") {
                                toast.clear();
                                _this.$toast.success('修改成功');
                                //刷新本地缓存
                                reLoadLoginInfo();
                                this.back();
                            } else {
                                toast.clear();
                                _this.$toast.fail(res.msg);
                            }
                        }).catch(err=> {toast.clear();});

            }
        }
    };
</script>

<style lang="less" scoped>
    .logout {
        bottom: 0;
        height: 50px;
        color: #ff5f16;
    }

    /* 本页公共样式 */
    .gray {
        color: #797d82;
    }

    .bott0 {
        bottom: 0;
    }

    .orange {
        color: #ff5f16
    }

    .f15 {
        font-size: 15px;
    }

    .bd-gray {
        border-bottom: 1px solid #f5f5f5;
    }

    // 设置
    header {
        .city {
            height: 44px;
            line-height: 44px;
        }
    }

    // 账号ID
    .id {
        height: 49px;
        line-height: 49px;

    }
</style>
<style lang="less">
    .van-nav-bar .van-icon , .van-nav-bar__text {
        color: #20a0ff;
    }
</style>

